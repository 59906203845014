import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import {Router} from '@angular/router';
import Utils from 'src/app/utils';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  public totalPrice : number;
  public firstName : string;
  public lastName : string;
  public phoneNum : string;
  public email : string;
  public address : string;
  public zipCode : string;
  public country : string;
  public city : string;

  public userData : any = {};

  public cardNum: any;
  public expMonth : any;
  public expYear : any;
  public cardCVC : any;
  public products : any = {};

  public slovPostShippingCost : number = 0;
  public serbianPostShippingCost : number = 0;

  public immunoGPrice : number = 31.0;
  public holexPrice : number = 34;
  public acidexPrice : number = 17;
  public q10Price : number = 19;

  public immunoGPriceSerbia : number = 2956;
  public holexPriceSerbia : number = 2985;
  public acidexPriceSerbia : number = 1880;
  public q10PriceSerbia : number = 2320;

  public dhlShippingPrices:number[][] = [
    [9.87,9.87,9.87],
    [10.97,10.97,10.97],
    [10.97,10.97,10.97],
    [11.5,11.5,11.5],
    [11.5,11.5,11.5],
    [12.55,12.55,12.55],
    [12.55,12.55,12.55],
    [14.15,14.20,14.25],
    [15.75,15.85,15.95],
    [17.35,17.50,17.65],
    [18.95,19.15,19.35],
    [20.55,20.80,21.05],
    [22.15,22.45,22.75],
    [23.75,24.10,24.45],
    [25.35,25.75,26.15],
    [26.95,27.40,27.85],
    [28.55,29.05,29.55]
  ]

  public slovenianPostShippingPrices = new Map<string, Map<number, number>>();

  public productWeights : Map<string, number>;

  public shippingCode : number = 0;

  public serbianPostShippingPrices = new Map<number, number>([
    [0.5, 550],
    [2, 550],
    [5, 550],
    [10, 550]
  ]);

  constructor(private http: HttpClient, private router: Router) {
    this.productWeights = new Map<string, number>();
    this.productWeights["immunoG500"] = 0.046;
    this.productWeights["immunoG100"] = 0.065;
    this.productWeights["acidEx"] = 0.189;
    this.productWeights["naturalQ10"] = 0.042;
    this.productWeights["holesterolExComplex"] = 0.251;

    this.slovenianPostShippingPrices["Slovenia"] = new Map<number, number>([
      [0.5, 2.8],
      [2, 4.9],
      [5, 6],
      [10, 7.2]
    ]);
    this.slovenianPostShippingPrices["Austria"] = new Map<number, number>([
      [2, 14.75],
      [5, 17.10],
      [10, 19.45]
    ]);
    this.slovenianPostShippingPrices["Germany"] = new Map<number, number>([
      [2, 14.75],
      [5, 17.10],
      [10, 19.45]
    ]);
    this.slovenianPostShippingPrices["Hungary"] = new Map<number, number>([
      [2, 14.75],
      [5, 17.10],
      [10, 23.34]
    ]);
    this.slovenianPostShippingPrices["Belgium"] = new Map<number, number>([
      [2, 19.20],
      [5, 22.65],
      [10, 28.16]
    ]);
    this.slovenianPostShippingPrices["Czechia"] = new Map<number, number>([
      [2, 19.20],
      [5, 22.65],
      [10, 25.60]
    ]);
    this.slovenianPostShippingPrices["Luxembourg"] = new Map<number, number>([
      [2, 19.20],
      [5, 24.92],
      [10, 39.68]
    ]);
    this.slovenianPostShippingPrices["Slovakia"] = new Map<number, number>([
      [2, 19.20],
      [5, 24.65],
      [10, 25.60]
    ]);
    this.slovenianPostShippingPrices["Italy"] = new Map<number, number>([
      [2, 19.20],
      [5, 24.65],
      [10, 26.88]
    ]);
    this.slovenianPostShippingPrices["Netherlands"] = new Map<number, number>([
      [2, 19.20],
      [5, 26.05],
      [10, 39.68]
    ]);
    this.slovenianPostShippingPrices["Portugal"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 34.55]
    ]);
    this.slovenianPostShippingPrices["Spain"] = new Map<number, number>([
      [2, 26.95],
      [5, 36.12],
      [10, 57.58]
    ]);
    this.slovenianPostShippingPrices["Denmark"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 46.06]
    ]);
    this.slovenianPostShippingPrices["France"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 46.06]
    ]);
    this.slovenianPostShippingPrices["Denmark"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 46.06]
    ]);
    this.slovenianPostShippingPrices["Greece"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 46.06]
    ]);
    this.slovenianPostShippingPrices["Poland"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 46.06]
    ]);
    this.slovenianPostShippingPrices["Romania"] = new Map<number, number>([
      [2, 26.95],
      [5, 40.64],
      [10, 62.51]
    ]);
    this.slovenianPostShippingPrices["Sweden"] = new Map<number, number>([
      [2, 26.95],
      [5, 31.61],
      [10, 51.00]
    ]);
    this.slovenianPostShippingPrices["Cyprus"] = new Map<number, number>([
      [2, 26.95],
      [5, 31.61],
      [10, 51.00]
    ]);
    this.slovenianPostShippingPrices["Bulgaria"] = new Map<number, number>([
      [2, 26.95],
      [5, 43.65],
      [10, 75.67]
    ]);
    this.slovenianPostShippingPrices["Estonia"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 32.90]
    ]);
    this.slovenianPostShippingPrices["Lithuania"] = new Map<number, number>([
      [2, 26.95],
      [5, 40.64],
      [10, 70.74]
    ]);
    this.slovenianPostShippingPrices["Latvia"] = new Map<number, number>([
      [2, 26.95],
      [5, 40.64],
      [10, 70.74]
    ]);
    this.slovenianPostShippingPrices["Finland"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 42.77]
    ]);
    this.slovenianPostShippingPrices["Finland"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 42.77]
    ]);
    this.slovenianPostShippingPrices["Ireland"] = new Map<number, number>([
      [2, 26.95],
      [5, 30.10],
      [10, 44.42]
    ]);
    this.slovenianPostShippingPrices["Croatia"] = new Map<number, number>([
      [2, 9.10],
      [5, 9.20],
      [10, 10.10]
    ]);

    this.checkProductQuantities();
    this.calculateWeightAndNumbers();
  }


  public regionCode : number = 0;
  public shippingPrice : number = 0;

  public priceTotal : number = 0;
  public priceSubtotal : number = 0;

  public numberOfProducts : number = 0;
  public shippingProvider : string = "";

  charge(token : string): Observable<any> {

    if(this.shippingCode == 0){
      this.shippingProvider = "Posta Slovenije";
    }else if(this.shippingCode == 1){
      this.shippingProvider = "DHL";
    }else{
      this.shippingProvider = "Posta Srbije";
    }

    var headers : HttpHeaders;

    var serbianPrice : number = 0;

    if(Utils.isSerbianVersion){
      serbianPrice = this.priceTotal;

      console.log("S price: " + serbianPrice.toFixed(2));

      headers = new HttpHeaders(
      {
          'Content-Type': 'application/json',
          'token' : token,
          'amount' : serbianPrice.toFixed(2).toString(),
          'shippingProvider' : this.shippingProvider,
          'products': JSON.stringify(this.products)
      });

    }else{
      headers = new HttpHeaders(
      {
          'Content-Type': 'application/json',
          'token' : token,
          'amount' : this.priceTotal.toString(),
          'shippingProvider' : this.shippingProvider,
          'products': JSON.stringify(this.products)
      });
    }

    console.log("TOTAL PRICE: " + this.priceTotal);
    
    return this.http.post('https://immuno-pharma-web-service.herokuapp.com/payment/charge', this.userData, {headers: headers});
    //return this.http.post('http://localhost:5001/payment/charge', this.userData, {headers: headers});

  }

  orderPayOnDelivery(token : string): Observable<any> {

    this.shippingProvider = "Posta Srbije";

    const headers = new HttpHeaders(
    {
        'Content-Type': 'application/json',
        'token' : token,
        'amount' : this.priceTotal.toString(),
        'shippingProvider' : this.shippingProvider,
        'products': JSON.stringify(this.products)
    });

    console.log("TOTAL PRICE: " + this.priceTotal);
    
    return this.http.post('https://immuno-pharma-web-service.herokuapp.com/payment/charge', this.userData, {headers: headers});
  }

  public isBasketEmpty() : boolean{
    console.log("number of products: " + this.numberOfProducts);

    if(this.numberOfProducts > 0)
      return false;
    else return true;
  }

  public calculateDHLShipping(regionNumber : number, productWeightTotal: number) : number{
    console.log("Calculating DHL shipping");
    var index : number;
    index = ((productWeightTotal - (productWeightTotal % 0.5)) / 0.5);
    console.log("index: " + index);
    if(regionNumber < 1 || regionNumber > 3){
      console.log("region number error. Region number: " + regionNumber);
      return 0;
    }

    var price = this.dhlShippingPrices[index][regionNumber-1];
    console.log("price: " + price);
    return price;
  }
  
  public checkProductQuantities(){
    if(localStorage.getItem("immuno100") != null){
      this.products["immunoG100"] = Number.parseInt(localStorage.getItem("immuno100"));
    }else{
      this.products["immunoG100"]=0;
    }

    if(localStorage.getItem("immuno500") != null){
      this.products["immunoG500"] = Number.parseInt(localStorage.getItem("immuno500"));
    }else{
      this.products["immunoG500"]=0;
    }

    if(localStorage.getItem("holex") != null){
      this.products["holesterolExComplex"] = Number.parseInt(localStorage.getItem("holex"));
    }else{
      this.products["holesterolExComplex"]=0
    }

    if(localStorage.getItem("acidex") != null){
      this.products["acidEx"] =  Number.parseInt(localStorage.getItem("acidex"));
    }else{
      this.products["acidEx"] = 0;
    }
    
    if(localStorage.getItem("q10") != null){
      this.products["naturalQ10"] = Number.parseInt(localStorage.getItem("q10"));
    }else{
      this.products["naturalQ10"] = 0;
    }
  }

  public calculateSlovPostShipping(countryName : string, productWeightTotal: number){

    var countryPrices : Map<number, number>;
    
    countryPrices = this.slovenianPostShippingPrices[countryName];
    if(countryPrices == null || countryPrices == undefined || this.slovenianPostShippingPrices[countryName] == undefined || this.slovenianPostShippingPrices[countryName] == null){
      console.log("ERROR in calculation of prices, country is: " + countryName);
      this.slovPostShippingCost = 15;
      return 15;
    }

    for(let entry of Array.from(countryPrices.entries())){
      if(productWeightTotal < entry[0]){
        return entry[1];
      }
    }

    console.log("ERROR in calculation of prices!!!" + countryName);
    return countryPrices[2];
  }

  public calculateSerbianPostShipping(productWeightTotal: number){
    
    if(this.serbianPostShippingPrices == undefined || this.slovenianPostShippingPrices == null){
      console.log("ERROR in calculation of prices, country is: Serbia");
      this.serbianPostShippingCost = 550;
    }

    for(let entry of Array.from(this.serbianPostShippingPrices.entries())){
      if(productWeightTotal < entry[0]){
        return entry[1];
      }
    }

    console.log("ERROR in calculation of prices!!! Serbia");
    return 550;
  }

  public calculateShipping() : number{

    var weight = this.calculateWeightAndNumbers();
    console.log("Calculating shipping, number of products: " + this.numberOfProducts);

    if(this.country == null || this.country == undefined){
      return 0;
    }

    if(this.numberOfProducts >= 10 && (this.country == "Slovenia" || this.country == "Serbia")){
      return 0;
    }
    if(this.numberOfProducts >= 20){
      return 0;
    }

    if(this.shippingCode == 0){
      return this.calculateSlovPostShipping(this.country, weight);
    }else if (this.shippingCode == 1){
      return this.calculateDHLShipping(this.regionCode, weight);
    }else{
      return this.calculateSerbianPostShipping(weight);
    }
  }



  public calculateWeightAndNumbers():number{
    
    this.checkProductQuantities();

    var weight : number = 0.0;
    var numberOfProducts = 0;

    var quantity = this.products["acidEx"];
    if(quantity != null && quantity != undefined && quantity > 0){
      console.log("acidex > 0: " + quantity);
      weight += quantity * this.productWeights["acidEx"];
      numberOfProducts += quantity;
    }
    
    quantity = this.products["immunoG100"];
    if(quantity != null && quantity != undefined && quantity > 0){
      console.log("immunoG > 0 :"  + quantity);
      weight += quantity * this.productWeights["immunoG100"];
      numberOfProducts += quantity;
    }

    quantity = this.products["immunoG500"];
    if(quantity != null && quantity != undefined && quantity > 0){
      console.log("immunoG > 0: " + quantity);
      weight += quantity * this.productWeights["immunoG500"];
      numberOfProducts += quantity;
    }

    quantity = this.products["holesterolExComplex"];
    if(quantity != null && quantity != undefined && quantity > 0){
      console.log("holex > 0: " + quantity);
      weight += quantity * this.productWeights["holesterolExComplex"];
      numberOfProducts += quantity;
    }

    quantity = this.products["naturalQ10"];
    if(quantity != null && quantity != undefined && quantity > 0){
      console.log("q10 > 0: " + quantity);
      weight += quantity * this.productWeights["naturalQ10"];
      numberOfProducts += quantity;
    }

    this.numberOfProducts = numberOfProducts;
    console.log("Calculated weight: " + weight + ". Q10 product weight: " + this.productWeights["naturalQ10"] + ", weights: " + this.productWeights);
    console.log("Number of products: " + this.numberOfProducts);

    return weight;
  }
 
  public recalculatePrice(){
  
    this.shippingPrice = Number(this.calculateShipping().toFixed(2));
    this.priceTotal = Number((this.priceSubtotal + this.shippingPrice).toFixed(2));
    console.log("Total price: " + this.priceTotal);
  }

  calculateSubtotalPrice() : number{
    this.checkProductQuantities();
    var subtotalPrice : number = 0;
    if(Utils.isSerbianVersion){
      subtotalPrice = this.products["immunoG100"] * this.immunoGPriceSerbia + this.products["immunoG500"] * this.immunoGPriceSerbia +
      this.products["holesterolExComplex"] * this.holexPriceSerbia + this.products["acidEx"] * this.acidexPriceSerbia +
      this.products["naturalQ10"] * this.q10PriceSerbia;
    }else{
      subtotalPrice = this.products["immunoG100"] * this.immunoGPrice + this.products["immunoG500"] * this.immunoGPrice +
      this.products["holesterolExComplex"] * this.holexPrice + this.products["acidEx"] * this.acidexPrice +
      this.products["naturalQ10"] * this.q10Price;
    }
    

    return Number(subtotalPrice.toFixed(2));
  }


}
